@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-time {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	@extend %font-bold;

	&__time {
		display: inline;
	}

	svg {
		margin-right: 4px;
	}
}
