.rc-collapse-anim-active {
	transition: height 0.2s ease-out;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-collapse-content {
	overflow: hidden;
}

.rc-collapse-content-inactive {
	display: none;
}
