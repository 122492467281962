@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.steps-description-block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	&__title {
		margin-bottom: 20px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;

		@include gridle_state(md) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__item {
		margin-bottom: 25px;
		display: flex;
		flex-direction: column;
		width: 100%;

		@include gridle_state(md) {
			margin-bottom: 10px;
			width: 25%;
		}
	}

	&__item-header {
		display: grid;
		grid-template-columns: auto 1fr;
		width: 100%;
	}

	&__item-index {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: $color-primary;
		color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 4px 15px 0 0;

		@include gridle_state(md) {
			margin: 4px 25px 0 0;
		}

		[class*="typography"] {
			line-height: 0;
		}
	}

	&__item-title {
		display: flex;
		align-items: center;

		@include gridle_state(md) {
			max-width: 15ch;
		}
	}

	&__item-description {
		margin-top: 10px;
	}
}
