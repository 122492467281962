@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../.styles/button";

.relative-link {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;

	&__text {
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}

	&--button {
		@extend %button;
		@extend %font-bold;
	}

	&--secondary {
		@extend %button--secondary;
		@extend %font-bold;
	}

	&--primary {
		@extend %button--primary;
		@extend %font-bold;
	}

	&--naked {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&--disabled {
		@extend %button--disabled;
		pointer-events: none;
	}
}
